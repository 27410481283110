import * as React from "react"
import ContentPageLayout from "../../components/ContentPageLayout"
import ContentSkeleton from "../../components/ContentSkeleton"
import Seo from "../../components/seo"
import Section from "../../components/content/section"
import config, { firebaseInit } from "../../config"
import { getSectionById } from "../../api/content"
import firebase from "firebase";

const SectionPage = (props) => {
    console.log("entramos")
    
    const profileUri = `${config.serverUri}/users`;
    const [contentLoading, setContentLoading] = React.useState(true)
    const [section, setSection] = React.useState()
    const [userHasSuscription, setUserHasSuscription] = React.useState(null)
    const [sectionSelected, setSectionSelected] = React.useState("home")
  
    const handleSectionFilterChange = (filteredId) => {
      setSectionSelected(filteredId)
    }

    React.useEffect(() => {

        let sectionId = props.params.name === "entrenamiento" ? "65301f5d965e9b12688fd045" : "65301f7b965e9b12688fd049"
        getSectionById(sectionId).then(data => {
            data.isBlock = false;
            setSection(data)
            setContentLoading(false)
        })
    }, [props.params.name])

    React.useEffect(() => {
        firebaseInit(firebase)
        firebase.auth().onAuthStateChanged(user => {
            // console.log(user)
            if (user) {
                fetch(`${profileUri}?email=${user.email}`)
                    .then(response => {
                        if(response){
                            response.json().then(dataUser => {
                              // console.log(dataUser)
                              if(dataUser[0] && dataUser[0].suscription) setUserHasSuscription(dataUser[0].suscription)
                            })
                          }
                    }).catch(error => {
                        console.log(error)
                    }).catch(error => {
                        console.log(error)
                    })
            } else {
                console.log("user is null")
                setUserHasSuscription(false)
            }
        })
    }, [])
    const suscription = (text)=>{
        text === "premium" || text === "free" ? setUserHasSuscription(text): setUserHasSuscription(false)
      }

    return (
        <ContentPageLayout location={props.location} sectionSelected={sectionSelected}>
            <Seo title={contentLoading || !section ? "" : section.label} />
            {contentLoading || !section ? <ContentSkeleton></ContentSkeleton> : <Section key={section._id} section={section} userHasSuscription={userHasSuscription ?? null} onFilterChange={handleSectionFilterChange}></Section>}
        </ContentPageLayout>)
}

export default SectionPage